import { Injectable } from '@angular/core';
import firebase from 'firebase/app'
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  public userToken: string;

  constructor(
  ) {
  }


  GetToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      // this.afAuth.auth.onAuthStateChanged(user => {
      //   if (user) {
      //     user.getIdToken().then(idToken => {
      //       this.userToken = idToken;
      //       resolve(idToken);
      //     });
      //   }
      // });
      firebase.auth().onAuthStateChanged(async function (user) {
        if (user) {
          await user.getIdToken().then(async function (data) {
            resolve(data);
          });
        }
      });
    })
  }

}