import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class InteractionService {
  private message = new BehaviorSubject('');
  private url = new BehaviorSubject('');
  private user = new BehaviorSubject(false);
  currentMessage = this.message.asObservable();
  status = this.user.asObservable();
  currentUrl = this.url.asObservable();
  constructor() { }


  getMessage(message: string) {
    this.message.next(message);
  }

  showUserData(status: boolean) {
    this.user.next(status)
  }

  currentRoute(url: string) {
    this.url.next(url);
  }
}
