import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { HomeComponent } from '../../pages/home/home.component';
import { InteractionService } from './../../_services/interaction.service';
import { ApiService } from '../../_services/api.service';
import { ProfileService } from '../../_services/profile.service';
import { CommomService } from 'src/app/common/commom.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import firebase from 'firebase/app';
import 'firebase/auth';
import { countryCode, country_codes } from '../../common/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { getBootstrapListener } from '@angular/router/src/router_module';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  userDetails = new FormGroup({
    countryCode: new FormControl('91'),
    phoneNumber: new FormControl('', [Validators.required]),
    otp: new FormControl('')
  });
  countryCode = countryCode;
  isNumber = false;
  loggedEmail: string;
  obj: any;
  modelSignup: any = {};
  page: any;
  model: any = {};
  msg: string = '';
  error = false
  loggedUser: string = ''
  passwordMatched = true
  transparentClass: string;
  textColor: string;
  signInModel: any = {}
  forgotEmail: string
  prefix = 10;
  countryPrefix = country_codes;
  hideCloseButton: boolean = false;
  @ViewChild(HomeComponent) home: HomeComponent;
  recaptchaVerifier: any;
  otpSent: boolean = false
  phoneNumber = null
  otp = null
  resendOtpUsed = false
  profiledetails: any;
  confirmationResult: firebase.auth.ConfirmationResult;
  constructor(private location: Location,    private commonService: CommomService,    private router: Router, private IntService: InteractionService, private spinner: NgxSpinnerService, private api: ApiService,public papi: ProfileService) {
    this.IntService.currentMessage.subscribe(message => {
      this.signInModel.countrycode = '91';
      this.page = message;
      if (message != 'search' && message != '') {
        this.hideCloseButton = true;
        $('#loginWithOtp').modal({ backdrop: 'static', keyboard: false });
        console.log(message)
      } else {
        this.hideCloseButton = false;
        $("#loginWithOtp").modal('show');
      }
    });

    this.IntService.currentUrl.subscribe(url => {
      console.log('url' + url)
      if (url == 'home') {
        this.transparentClass = 'transparentHeader';
      } else {
        this.transparentClass = 'a';
      }
    })
  }

  async ngOnInit() {
    this.spinner.hide()

    if (localStorage.getItem('email')) {
      this.loggedEmail = localStorage.getItem('access_token');
    } else if(localStorage.getItem('mobileNos')) {
      this.loggedEmail = localStorage.getItem('access_token');
    }
    else {
      this.loggedEmail = null;
      localStorage.setItem('access_token',null);
    }

    if (localStorage.getItem('email') || localStorage.getItem('mobileNos')) {
    (await this.papi.getProfileDetails()).subscribe(res => {
      console.log('profile details', res);
      this.profiledetails = res;
      if(this.profiledetails.name!=null) {
        this.loggedUser = this.profiledetails.name;
      }
      else if(localStorage.getItem('email')!=null) {
        this.loggedUser = localStorage.getItem('email')
      }
      else {
        this.loggedUser = localStorage.getItem('mobileNos')
      }

      $('p#hey').text("Hi "+this.loggedUser)
      this.spinner.hide()
    },
    (error) => {
      console.log("header:"+error)
    }
    )
  }

    //   new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    console.log(this.router.url)
    console.log(localStorage.getItem('email'))
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible'
    });

    $(function() { //we have to wait for the DOM to be ready otherwise it won't work
      $('#loginWithOtp').on('shown.bs.modal', function (e) { 
        console.log('shown')
        this.msg = null;
        $("#mobile-nav").removeClass('nav-active')
        $("#mobile-body-overly").attr('style','display:none')
        $("body").removeClass('mobile-nav-active')
        $("#mobile-nav-toggle i").removeClass('fa fa-times')
        $("#mobile-nav-toggle i").addClass('fa fa-bars')
          // $('.nav-active#mobile-nav').attr('style', 'width:100%; display: flex; padding:0 !important;');
          // $('#navbarSupportedContent').attr('style','padding:0 !important;');
          // $('#navbarSupportedContent .collapse').attr('style','padding:0 !important;');
          // $('.nav-item').attr('style','padding:0 !important; ');

          // $('#mobile-nav.collapse').attr('style', 'width:0%; padding:0 !important');//set the default background-color and padding to prevent the shifting phenomenon 
      })

      // $('#login').on('hidden.bs.modal', function (e) { 
      //   if($('#mobile-nav').hasClass('nav-active'))
      //   {
      //     $('#mobile-nav').removeClass('nav-active')
      //   }
      //   else
      //   {
      //     $('#mobile-nav').addClass('nav-active')
      //   }
      // });

      

  });  

  }

  closeModel() {
    this.msg =null;
    if (this.page == 'rent' || this.page == 'sell' || this.page == 'pg-hostel-page' || this.page == 'property-listing') {
      this.page = '';
      this.router.navigate(['/home']);
      // this.location.back();
    }
    if (this.page == 'property-details') {
      this.IntService.getMessage("modal-closed");
      //this.router.navigate(['/home']);
    }
    if($('#mobile-nav').hasClass('nav-active'))
                {
                  console.log("has class")
                  $('#mobile-nav').removeClass('nav-active')
                }
  }

  logoutAndProfile() {
    firebase.auth().signOut().then(() => {
      // Sign-out successful.
      console.log("sign out successful")
      this.router.navigate(['/home']);
                  this.ngOnInit();
                  window.location.reload();
    }).catch((error) => {
      // An error happened.
      console.log(error)
    });
    localStorage.clear();

    if ($(window).width() < 768)
                {
                  this.router.navigate(['/home']);
                  this.ngOnInit();
                  window.location.reload();
                }
                else
                {
                  this.router.navigate(['/home']);
                  this.ngOnInit();  
                  window.location.reload();              
                }
    

}

  openSignup() {
    this.msg = null;

    $("#loginWithOtp").modal("hide");
    $("#login").modal("hide");

    if (this.page == 'rent' || this.page == 'sell' || this.page == 'pg-hostel-page') {
      this.page = '';
      this.router.navigate(['/home']);
      // this.location.back();
    }

    (this.page == '') || (this.page == 'search') ? $("#signup").modal("show") : $('#signup').modal({ backdrop: 'static', keyboard: false });


  }

  openSigin() {
    this.msg = null;
    $("#signup").modal("hide");
    $("#mobile-nav").removeClass('nav-active')
    $("#loginWithOtp").modal("show");
  }

  loginWithOtp() {
    this.msg = null;
    $("#login").modal("hide");
    $("#mobile-nav").removeClass('nav-active')
    $('#loginWithOtp').modal("show");
  }

  loginWithEmailPassword() {
    this.msg = null;
    $('#loginWithOtp').modal("hide");
    // $('#login').modal({ backdrop: 'static', keyboard: false });
    $("#mobile-nav").removeClass('nav-active')
    $("#login").modal("show");
  }

  forgotPassword() {
    this.msg = null;
    $("#login").modal("hide");
    $('#forgotPassword').modal("show");
  }

  goto(key) {
    if (key == 'aboutus1') {
      var elmnt = document.getElementById("aboutus1");
    } else {
      var elmnt = document.getElementById("contact");
    }

    elmnt.scrollIntoView();
  }

  onSubmit() {
    // login with credentials and check if email is verified if yes login user or else tell to verify email
    this.spinner.show();
    firebase.auth().signInWithEmailAndPassword(this.model.email, this.model.password)
      .then((user) => {
        if (user.user.emailVerified) {
          this.textColor = 'green';
          user.user.getIdToken().then(idToken => {
            localStorage.setItem("access_token", idToken);
            localStorage.setItem("email", this.model.email);
            //this.loggedUser = localStorage.getItem("email")
            this.IntService.showUserData(true);
            this.spinner.hide();

            this.msg = 'logged in successfully';
            if(this.page == 'property-details') {
              sessionStorage.setItem('displayContact','true')
            }
            window.location.reload();
            setTimeout(() => {
              this.msg = null;
              if (idToken) {
                if($('#mobile-nav').hasClass('nav-active'))
                {
                  console.log("has class")
                  $('#mobile-nav').removeClass('nav-active')
                }
                
                if ($(window).width() < 768)
                {
                  $("#login").modal('hide');
                  window.location.reload();
                }
                else
                {
                  $("#login").modal('hide');
                  window.location.reload();
                }

                this.ngOnInit();
              }
            }, 2000);
          }).catch(error => {
            this.msg = "Sign in failed!!"
            $("#login").modal('hide');
            this.spinner.hide();
            console.log(error)
            if (this.page == 'rent' || this.page == 'sell' || this.page == 'pg-hostel-page') {
              this.page = '';
              this.router.navigate(['/home']);
              // this.location.back();
            }
          })
        } else {
          this.textColor = "red"
          this.spinner.hide()
          this.msg = 'Please verify your email';
          setTimeout(() => {
            this.msg = null;
            $("#login").modal('hide');
            window.location.reload();
          }, 2000);
          if (this.page == 'rent' || this.page == 'sell' || this.page == 'pg-hostel-page') {
            this.page = '';
            this.router.navigate(['/home']);
            // this.location.back();
          }
        }
        

      })
      .catch((error) => {
        var errorMessage = error.message;
        this.textColor = 'red';
        this.msg = errorMessage;
        this.spinner.hide();
        setTimeout(() => {
          this.msg = null;
          $("#login").modal('hide');
        }, 15000)

        if (this.page == 'rent' || this.page == 'sell' || this.page == 'pg-hostel-page') {
          this.page = '';
          this.router.navigate(['/home']);
          // this.location.back();
        }
      });

      
        if(this.profiledetails.name!=null) {
          this.loggedUser = this.profiledetails.name;
        }
        else if(localStorage.getItem('email')!=null) {
          this.loggedUser = localStorage.getItem("email");
        }
        else {
          this.loggedUser = null;
        }
    

  }
  sendEmailVerification() {
    firebase.auth().currentUser.sendEmailVerification()
      .then(function () {
        // Verification email sent.
      })
      .catch(function (error) {
        // Error occurred. Inspect error.code.
        console.log("error", error)
      });
  }
  onSubmit1() {
    if (this.modelSignup.password === this.modelSignup.confirm_password) {
      this.spinner.show();
      this.passwordMatched = true
      firebase.auth().createUserWithEmailAndPassword(this.modelSignup.email, this.modelSignup.password)
        .then((user) => {
          this.msg = "Complete the Signup by clicking on verfication link sent to Mail.";
          this.sendEmailVerification();
          setTimeout(() => {
            this.msg = null;
            $("#signup").modal('hide');
          }, 5000);
          this.spinner.hide();
        })
        .catch((error) => {
          console.log(error)
          var errorMessage = error.message;
          this.msg = errorMessage
          this.spinner.hide();
        });
    } else {
      this.passwordMatched = false;
    }

  }




  sendOtp(resend) {
    if (resend === 'resend') {
      this.resendOtpUsed = true
    }
    
    if(this.userDetails.value.phoneNumber){
      if (!this.commonService.validateNosAndDecimal(this.userDetails.value.phoneNumber)) {
        this.msg = ''
        firebase.auth().signInWithPhoneNumber(`+${this.userDetails.value.countryCode}${this.userDetails.value.phoneNumber}`, this.recaptchaVerifier)
          .then((confirmationResult) => {
            // SMS sent. Prompt user to type the code from the message, then sign the
            // user in with confirmationResult.confirm(code).
            this.confirmationResult = confirmationResult;
            this.otpSent = true;
          }).catch(err => {
            console.log(err)
            this.error = true
            if(err.code == 'auth/invalid-phone-number') {
              this.msg = 'Please enter valid mobile number'
            }
            else {
            this.msg = "Too many failed attempts. Try again later!"
            }
          })
      }
      else {
        this.error = true
      this.msg = "Please enter valid mobile number"
      }
    }else{
      this.error = true
      this.msg = "Please enter mobile number"
    }
  }

  signIn() {
    let otp = this.userDetails.value.otp.toString()
    this.confirmationResult.confirm(otp).then(User => {      
      localStorage.setItem('mobileNos', `+${this.userDetails.value.countryCode}${this.userDetails.value.phoneNumber}`)
      firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
          //this.loggedUser = localStorage.getItem('mobileNos')
          // idToken can be passed back to server.
          console.log("id token", idToken)
          this.textColor = 'green';
          localStorage.setItem("access_token", idToken);
          localStorage.setItem('mobileNos', `+${this.userDetails.value.countryCode}${this.userDetails.value.phoneNumber}`)
          this.ngOnInit();
          this.msg = 'logged in successfully';
          if(this.page == 'property-details') {
            sessionStorage.setItem('displayContact','true')
          }
          window.location.reload();
          setTimeout(() => {
            this.msg = null;
            this.otpSent = false;
            if (idToken) {
              $("#loginWithOtp").modal('hide');
              window.location.reload();
            }
          }, 2000);
        })
        .catch((error) => {
          // Error occurred.
        });

    }).catch(err => {
      this.error = true;
      this.msg = "Please enter valid OTP"
    });

    
      if(this.profiledetails.name!=null) {
        this.loggedUser = this.profiledetails.name;
      }
      else if(localStorage.getItem("mobileNos")!=null) {
        this.loggedUser = localStorage.getItem("mobileNos");
      }
      else {
        this.loggedUser = null
      }
    
  }

  async sendForgotPasswordEmail() {
    var auth = firebase.auth();
    let message: Boolean = false;
    let email: Boolean = false
    await auth.sendPasswordResetEmail(this.forgotEmail).then(function () {
      message = true;
    }).catch(function (error) {
      // An error happened.
      console.log(error)
      message = false;

      if (error.code === 'auth/user-not-found') {
        email = true
      }

    });
    if (message) {
      this.msg = "Reset password email sent successfully!!"
    } else {
      if (email) {
        this.msg = "Email not found"
      } else {
        this.msg = "Something went wrong.Please try again later!!"
      }

    }
  }

  getPrefix() {
    this.countryPrefix.filter(element => {
      if (element.code === this.userDetails.value.countryCode) {
        this.prefix = element.prefix
      }
    })
  }
}


