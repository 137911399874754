import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import firebase from 'firebase/app';
@Injectable({
  providedIn: 'root'
})

export class ApiService {

  baseUrl = environment.base_url;

  private subject = new Subject<any>();

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }


  constructor(private http: HttpClient) {

  }

  getUser(inputs) {
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });
    let options = {
      headers: headers
    }
    return this.http.post(this.baseUrl + 'api/user/login', inputs, options);
  }

  createUser(inputs) {
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });
    let options = {
      headers: headers
    }
    return this.http.post(this.baseUrl + 'api/user/signup', inputs, options);
  }

  getDistricts() {

    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });

    let options = {
      headers: headers
    }

    return this.http.get(this.baseUrl + 'api/dropdowns/districts', options);
  }

  getBuy(inputs) {
    console.log(inputs);
    //return this.http.get(, inputs );
    return this.http.post<any>(this.baseUrl + 'api/buy/search', inputs);
  }
  getProductDetail(inputs) {

    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });

    let options = {
      headers: headers
    }


    //JSON.parse(inputs);
    //console.log(inputs);
    // const inputs1 = JSON.parse("{ \"property_id\" :" + 12 + "}");

    return this.http.post(this.baseUrl + 'api/property/telangana/buy/detail_property', inputs, options);
  }


  async sellProperty(inputs) {
    // let acces_token_value = localStorage.getItem("access_token");
    let acces_token_value;
    await firebase.auth().currentUser.getIdToken()
      .then((idToken) => {
        console.log(idToken)
        acces_token_value = idToken
      }).catch(err => {
        console.log(err)
      })
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': "Bearer " + acces_token_value

    });
    let options = {
      headers: headers
    }
    return this.http.post(this.baseUrl + '/api/sell/add_property', inputs, options);
  }

  getMandals(inputs) {

    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });

    let options = {
      headers: headers
    }


    //JSON.parse(inputs);
    //console.log(inputs);
    // const inputs1 = JSON.parse("{ \"property_id\" :" + 12 + "}");

    return this.http.post(this.baseUrl + 'api/dropdowns/mandals', inputs, options);
  }

  getLocations(inputs) {

    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
    });

    let options = {
      headers: headers
    }


    //JSON.parse(inputs);
    //console.log(inputs);
    // const inputs1 = JSON.parse("{ \"property_id\" :" + 12 + "}");

    return this.http.post(this.baseUrl + 'api/dropdowns/locations', inputs, options);
  }

  forgotPassword(data) {
    return this.http
      .post<any>(this.baseUrl + "/forgotPassword", data)
      .pipe(
        map(res => {
          return res;
        })
      );
  }
  async addProperty(info: any) {
    let acces_token_value
    await firebase.auth().currentUser.getIdToken()
      .then((idToken) => {
        console.log(idToken)
        localStorage.setItem("access_token", idToken);
        acces_token_value = idToken
      }).catch(err => {
        console.log(err)
      })
    // let acces_token_value = localStorage.getItem("access_token");
    let headers = new HttpHeaders({
      // 'Accept': 'application/json, text/plain, application/xml,  */*',
      // "Content-Type": "application/json",
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': 'Bearer ' + acces_token_value
    });
    let options = {
      headers: headers
    }
    return this.http.post<any>(this.baseUrl + 'api/v1/sale/add', info, options).pipe(map(res => {
      return res;
    }))
  }

  async addRentProperty(info: any, path: String) {
    // let acces_token_value = localStorage.getItem("access_token");
    let acces_token_value;
    await firebase.auth().currentUser.getIdToken()
      .then((idToken) => {
        console.log(idToken)
        acces_token_value = idToken
      }).catch(err => {
        console.log(err)
      })
    let headers = new HttpHeaders({
      // 'Accept': 'application/json, text/plain, application/xml,  */*',
      // "Content-Type": "application/json",
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': 'Bearer ' + acces_token_value
    });
    let options = {
      headers: headers
    }
    return this.http.post<any>(`${this.baseUrl}api/v1/${path}/add`, info, options).pipe(map(res => {
      return res;
    }))
  }

  async updateProperty(info: any, id) {
    // let acces_token_value = localStorage.getItem("access_token");
    let acces_token_value
    await firebase.auth().currentUser.getIdToken()
      .then((idToken) => {
        console.log(idToken)
        acces_token_value = idToken
      }).catch(err => {
        console.log(err)
      })
    let headers = new HttpHeaders({
      // 'Accept': 'application/json, text/plain, application/xml,  */*',
      // "Content-Type": "application/json",
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': 'Bearer ' + acces_token_value
    });
    let options = {
      headers: headers
    }
    // /api/v1/<property_for>/update/<id>
    return this.http.post<any>(`${this.baseUrl}api/v1/sale/update/${id}`, info, options).pipe(map(res => {
      return res;
    }))
  }
  /* update rent property */
  async updateRentProperty(info: any, property_for, id) {
    // let acces_token_value = localStorage.getItem("access_token");
    let acces_token_value;
    await firebase.auth().currentUser.getIdToken()
      .then((idToken) => {
        console.log(idToken)
        acces_token_value = idToken
      }).catch(err => {
        console.log(err)
      })
    let headers = new HttpHeaders({
      // 'Accept': 'application/json, text/plain, application/xml,  */*',
      // "Content-Type": "application/json",
      // 'Access-Control-Allow-Headers': 'Content-Type',
      'Authorization': 'Bearer ' + acces_token_value
    });
    let options = {
      headers: headers
    }
    return this.http.post<any>(`${this.baseUrl}api/v1/${property_for}/update/${id}`, info, options).pipe(map(res => {
      return res;
    }))
  }

  /* Serach Property */
  serarchProperty(info: any, data) {
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type'
    });
    let options = {
      headers: headers
    }
    let url = 'api/v1/properties/search?';
    let params;
    if (info) {
      params = `lattitude=${info.lattitude}&longitude=${info.longitude}&property_for=${info.property_for}&property_type=${info.property_type}&limit=${data.limit}&offset=${data.offset}`
    }
    return this.http.get<any>(`${this.baseUrl}${url}${params}`, options).pipe(map(res => {
      return res;
    }))
  }
  /* Show Property Details */
  detail_property(propertyId: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type'
    });
    let options = {
      headers: headers
    }
    // if (propertyId.property_for) {
    return this.http.get<any>(this.baseUrl + `api/v1/properties/details/${propertyId.property_id}`).pipe(map(res => {
      return res;
    }))
    // }
    //  else {
    //   return this.http.post<any>(this.baseUrl + 'api/buy/detail_property', propertyId, options).pipe(map(res => {
    //     return res
    //   }))
    // }

  }
  detail_propertyRent(propertyId: any) {
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type'
    });
    let options = {
      headers: headers
    }
    return this.http.post<any>(this.baseUrl + 'api/rent/detail_property', propertyId, options).pipe(map(res => {
      return res;
    }))
  }
  getAllProperties() {
    return this.http.get<any>(`${this.baseUrl}api/v1/user/getallpropertiesbyid`);
  }



  getLatestProperty() {
    let headers = new HttpHeaders({
      'Accept': 'application/json, text/plain, application/xml,  */*',
      "Content-Type": "application/json",
      'Access-Control-Allow-Headers': 'Content-Type',
      "Cache-Control": "no-cache, no-store, must-revalidate"
    });

    let options = {
      headers: headers
    }

    return this.http.get<any>(`${this.baseUrl}api/v1/user/latestProperties`, options);
  }

  getAmenities(type) {
    return this.http.get<any>(`${this.baseUrl}api/v1/properties/amenities/${type}`);
  }

  getContactInfo(id) {
    return this.http.get<any>(`${this.baseUrl}api/v1/properties/get_contactinfo/${id}`);
  }

  getFav(id) {
    return this.http.get<any>(`${this.baseUrl}api/v1/user/is_fav/${id}`);
  }
}

